import React from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "../layouts/AdminHeader";
import AdminSider from "../layouts/AdminSider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import BASE_URL from "../baseUrl/baseUrl";
import axios from "axios";

function AdminAgent(props) {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [state, setState] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(state.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = state?.slice(startIndex, startIndex + itemsPerPage);
  useEffect(() => {
    axios.get(`${BASE_URL}/becomeOurAgent`).then((res) => {
      console.log(res);
      setFlag(true);
      setState(res.data?.result);
    });
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin User Edit</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>become Our Agent</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              width: "94%",
              margin: "1%",
            }}
          >
          </div>
          <div className="table-container-main">
            <div className="table-container-child">
              <table className="table text-center " style={{ width: "100%", margin: "1%",border:"2px" }}>
                <thead >
                  <tr className="bg-[black]">
                    <th scope="col">S.no</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Tel.No</th>
                    <th scope="col">line</th>
                    <th scope="col">weChat</th>
                    <th scope="col">whatsApp</th>
                    {/* <th scope="col">Action</th> */}

                  </tr>
                </thead>
                <tbody>
                  {currentData?.length === 0 && flag ? (
                    <tr className="text-center">
                      <td colSpan="8">No Data</td>
                    </tr>
                  ) : currentData?.length === 0 && flag === false ? (
                    <tr className="text-center">
                      <td colSpan="8">Loading ...</td>
                    </tr>
                  ) : (
                    currentData?.map((res, key) => {
                      return (
                        <tr key={key}>
                           <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                          <td>{res?.firstname}</td>
                          <td>{res?.tel}</td>
                          <td>{res?.line}</td>
                          <td>{res?.weChat}</td>
                          <td>{res?.whatApps}</td>
                          {/* <td>
                            <button
                              className="btn btn-danger mb-1 w-[100px]"
                            //   onClick={() => deleteUser(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-trash mr-1"></span>
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }} className="mb-4">
            <div>
              {currentData.length === 0 ? (
                null
              ) : (
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAgent;
