import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";
import { State } from "country-state-city";
const AdminagentSaysEdit = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const toast = useToast();
    const [language, setLanguage] = useState("");
    const [Experience, setExperience] = useState()
    const [Area, setArea] = useState("");
    const [name, setName] = useState("");
    const [companyname, setcompanyname] = useState("");
    const [icon, setIcon] = useState();
    const [url, setUrl] = useState();
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [description, setDescription] = useState(false);
    const [rating, setRating] = useState("");
    const [comment, setcomment] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [destination, setDestination] = useState("");
    const [userName, setuserName] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [provinceName, setProvinceName] = useState();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    useEffect(() => {
        const allStates = State.getAllStates();
        setProvinces(allStates);
    }, []);
    const loca = useLocation();
    useEffect(() => {
        setName(loca?.state?.title);
        setUrl(loca?.state?.img);
        setIcon(loca?.state?.img);
        setuserName(loca.state?.userName);
        setRating(loca?.state?.rating);
        setcomment(loca?.state?.comment);
        setDestination(loca.state?.destination);
        setDescription(loca.state?.description);
        setArea(loca.state?.Area);
        setLanguage(loca.state?.language);
        setExperience(loca.state?.Experience);
        setcompanyname(loca.state?.companyname);
    }, [params.id || loca]);

    const onCancel = () => {
        navigate("/admin/testimonial");
    };
    const postData = () => {
        if (name === "") {
            toast({
                title: "Error",
                description: "Name field is empty!!!",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        const formData = new FormData();
        formData.append("title", name);
        formData.append("userName", userName);
        formData.append("description", description);
        formData.append("destination", destination);
        formData.append("img", icon);
        formData.append("comment", comment);
        formData.append("companyname", companyname);
        formData.append("rating", rating);
        formData.append("language", language);
        formData.append("Area", Area);
        formData.append("Experience", Experience);
        formData.append("provinceName", provinceName);
        setFlag(true);
        axios
            .put(`${BASE_URL}/admin/pepleSay/edit/${params.id}`, formData)
            .then((res) => {
                setFlag(false);
                toast({
                    title: "Success",
                    description: "It has been updated successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
                navigate("/admin/testimonial");
            })
            .catch((err) => {
                setFlag(false);
                setError(true);
                setErrorMsg(err);
            });
    };

    const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setUrl(base64);
        setIcon(e.target.files[0]);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Agent Says</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2> Testimonail Edit</h2>
                    </div>
                    <div
                        className="acr-user-content"
                        style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
                    >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            style={{
                                width: "70%",
                                padding: "2%",
                            }}
                        >
                            <div className="form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    value={userName}
                                    onChange={(e) => setuserName(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Username"
                                    name="username"
                                />
                            </div>
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Title"
                                    name="username"
                                />
                            </div>
                            <div className="form-group">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    value={companyname}
                                    onChange={(e) => setcompanyname(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Title"
                                    name="username"
                                />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Description"
                                    name="description"
                                />
                            </div>
                            <div className="form-group">
                                <label>Comment</label>
                                <input
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setcomment(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="comment"
                                    name="comment"
                                />
                            </div>
                            <div className="form-group">
                                <label>Destination</label>
                                <input
                                    type="text"
                                    value={destination}
                                    onChange={(e) => setDestination(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Destination"
                                    name="destination"
                                />
                            </div>
                            <div className="form-group">
                                <label>Experience</label>
                                <input
                                    type="text"
                                    value={Experience}
                                    onChange={(e) => setExperience(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Experience"
                                    name="Experience"
                                />
                            </div>
                            <div className="form-group">
                                <label>Select Language</label>
                                <select
                                    className="form-control"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                >
                                    <option>Select language</option>
                                    <option value={"English"}>English</option>
                                    <option value={"Thai"}>Thai</option>
                                    <option value={"Indian"}>Indian</option>
                                    <option value={"Chineese"}>Chineese</option>
                                    <option value={"Russian"}>Russian</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Area Cover</label>
                                <input
                                    type="text"
                                    value={Area}
                                    onChange={(e) => setArea(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Area Cover"
                                    name="Area"
                                />
                            </div>
                            <div className="form-group">
                                <label>Provinces</label>
                                <select
                                    className="form-control"
                                    value={provinceName}
                                    onChange={(e) => setProvinceName(e.target.value)}>
                                    {provinces.map((province) => (
                                        <option key={province.id} value={province.name}>
                                            {province.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Rating Stars</label>
                                <select
                                    value={rating}
                                    className="form-control form-control-light"
                                    onChange={(e) => setRating(e.target.value)}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="icon" className="cursor-pointer">
                                    Select Image
                                </label>
                                <input
                                    onChange={onUpload}
                                    type="file"
                                    id="icon"
                                    name="icon"
                                    style={{ display: "none" }}
                                />
                                {url && url.slice(0, 7) === "uploads" ? (
                                    <img src={`${url}`} alt="Icon" style={{ width: "100px" }} />
                                ) : (
                                    <img src={url} alt="Icon" style={{ width: "100px" }} />
                                )}
                            </div>
                            <div className="form-group text-right">
                                {flag ? (
                                    <button type="Submit" disabled className="btn btn-primary">
                                        <span className="fa fa-save"></span> Saving...
                                    </button>
                                ) : (
                                    <button
                                        type="Submit"
                                        onClick={() => postData()}
                                        className="btn btn-primary"
                                    >
                                        <span className="fa fa-save"></span> Save
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => onCancel()}
                                >
                                    <span className="fa fa-reply"></span> Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminagentSaysEdit;
