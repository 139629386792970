import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";

const AdminHeaderEdit = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const loca = useLocation()
  const { state } = loca
  var subItemTitleId = ''

  const [id, setId] = useState("");
  const [titleName, setTitleName] = useState("")
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [subLists, setSubList] = useState("");
  const [itemTitle, setItemTitle] = useState("");
  const [itemTitledes, setItemTitleDes] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [url, setUrl] = useState();
  const [icon, setIcon] = useState();


  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onCancel = () => {
    navigate("/admin/headercontent");
  };
  const handleEditTitle = () => {
    // Update the title using axios PUT request
    const requestData = {
      title: title,
    };

    axios
      .put(
        `${BASE_URL}/NavList/api/mainlists/${id}`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setFlag(false);
        toast({
          title: 'Success',
          description: 'Title has been updated successfully.',
          status: 'success',
          duration: 2000,
          variant: 'left-accent',
          position: 'top-right',
          isClosable: true,
        });
        navigate("/admin/headercontent");
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
  };


  const postData = () => {
    if (subLists === "") {
      toast({
        title: "Error",
        description: "Name field is empty!!!",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }

    const requestData = {
      title: subLists, // Assuming subLists is a string containing the title
    };

    axios.post(`${BASE_URL}/NavList/api/mainlists/${id}/sublists`, requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        subItemTitleId = res.data._id
        setFlag(false);
        toast({
          title: "Success",
          description: "It has been updated successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        axios.post(
          `${BASE_URL}/NavList/mainlists/${id}/sublists/${subItemTitleId}/items`, formData)
          .then((res) => {
            setFlag(false);
            toast({
              title: "Success",
              description: "It has been updated successfully.",
              status: "success",
              duration: 2000,
              variant: "left-accent",
              position: "top-right",
              isClosable: true,
            });
            navigate("/admin/headercontent");
          })
          .catch((err) => {
            setFlag(false);
            setError(true);
            setErrorMsg(err);
          });
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
    const formData = new FormData();
    formData.append("title", itemTitle);
    formData.append("description", itemTitledes);
    formData.append("image", icon);

  };

  const onUpload = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setUrl(base64);
    setIcon(e.target.files[0]);
  };

  useEffect(() => {
    setTitle(state.title)
    setId(state._id)
  }, [])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Helmet>
        <title>
          Buy Home For Less | Admin Currency Create
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Header Edit </h2>
          </div>
          <div
            className="acr-user-content"
            style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{
                width: "70%",
                padding: "2%",
              }}
            >
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Title"
                  name="Title"
                />
              </div>
              <div className="form-group text-right">
                {flag ? (
                  <button type="Submit" disabled className="btn btn-primary">
                    <span className="fa fa-save"></span> Saving...
                  </button>
                ) : (
                  <button
                    type="Submit"
                    onClick={() => handleEditTitle()}
                    className="btn btn-primary"
                  >
                    <span className="fa fa-save"></span> Save
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => onCancel()}
                >
                  <span className="fa fa-reply"></span> Cancel
                </button>
              </div>
            </form>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeaderEdit;
