import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminHeaderReference = (props) => {
    const navigate = useNavigate();
    const toast = useToast();

    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [filter, setFilter] = useState([])

    useEffect(() => {
        axios
            .get(`${BASE_URL}/get-users`)
            .then((res) => {
                setFlag(true);
                setData(res?.data.result);
            });
    }, []);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Agent Says</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>s
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Referrals</h2>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "94%",
                            margin: "1%",
                        }}
                    >

                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "40vh" : "0" }}>
                        <table
                            className="table-striped table-bordered text"
                            style={{ width: "90%", margin: "1%" }}
                        >
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Referial Id</th>
                                    <th>referUrl</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length === 0 && flag ? (
                                    <tr className="text-center">
                                        <td colSpan="4">No Data</td>
                                    </tr>
                                ) : data?.length === 0 && flag === false ? (
                                    <tr className="text-center">
                                        <td colSpan="4">Loading ...</td>
                                    </tr>
                                ) : (
                                    data.filter((x) => x.referUrl).map((res, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{res.email}</td>
                                                <td>{res.referUrl}</td>
                                                <td>{res.count}</td>

                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminHeaderReference;
