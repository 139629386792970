import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "../layouts/AdminHeader";
import AdminSider from "../layouts/AdminSider";
import { useState } from "react";
import axios from "axios";
import BASE_URL from "../baseUrl/baseUrl";
import { useToast } from "@chakra-ui/react";

function AdminSellus(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false); // Moved collapsed state to AdminPanel
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [totalPage, settotalPage] = useState();
  const toast = useToast();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const fetchProperties = (page) => {
    axios
      .get(`${BASE_URL}/sellPropertyToUs/?page=${page}&limit=10`)
      .then((res) => {
        console.log(res);
        setFlag(true);
        settotalPage(res?.data?.pagination?.totalPages)
        setData(res?.data?.data);
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err.message);
      });
  };
  useEffect(() => {
    fetchProperties(currentPage);
  }, [currentPage]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const deleteSell = (id) => {
    axios
      .delete(`${BASE_URL}/sellPropertyToUs/${id}`)
      .then((res) => {
        toast({
          title: "Success",
          description: "Currency deleted successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        setData(()=>data?.filter((item)=>item?._id !== id))
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err);
      });
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div
            style={{ display: "flex", justifyContent: "center", padding: "3%" }}
          >
            <h2>Sell Property To Us</h2>
          </div>
          <div className="table-container-main">
            <div className="table-container-child">
              <table
                className="table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Property Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 && flag ? (
                    <tr className="text-center">
                      <td colSpan="5">No Data</td>
                    </tr>
                  ) : data.length === 0 && flag === false ? (
                    <tr className="text-center">
                      <td colSpan="5">Loading ...</td>
                    </tr>
                  ) : (
                    data?.map((res, key) => {
                      return (
                        <tr key={key}>
                          <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                          <td>{res.name}</td>
                          <td>{res.typeofProperty}</td>
                          <td>
                            <button
                              className="btn btn-danger w-[100px]  mb-1"
                              onClick={() => deleteSell(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-trash mr-1"></span>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="mb-4">
                {totalPage === 1 ? null : (
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>
                      {[...Array(totalPage)].map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPage ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSellus;
