import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminCurrency = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/get-currencies`)
      .then((res) => {
        setFlag(true);
        setData(res.data.result);
      });
  }, []);

  const editCurrency = (id) => {
    navigate(`/admin/currency/${id}`);
  };
  const deleteCurrency = (id) => {
    axios
      .delete(`${BASE_URL}/admin/delete/currency/${id}`)
      .then((res) => {
        setData(res.data.result);
        toast({
          title: "Success",
          description: "Currency deleted successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err);
      });
  };

  const addCurrency = () => {
    navigate("/admin/currency/create");
  };
  const itemsPerPage = 10;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Currency</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Currency Manage</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              width: "94%",
              margin: "1%",
            }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={() => addCurrency()}
            >
              <span className="fa fa-plus"></span> Add Currency
            </button>
          </div>
          <div className="table-container-main">
            <div className="table-container-child">
              <table
                className="table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Symbol</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.length === 0 && flag ? (
                    <tr className="text-center">
                      <td colSpan="5">No Data</td>
                    </tr>
                  ) : currentData.length === 0 && flag === false ? (
                    <tr className="text-center">
                      <td colSpan="5">Loading ...</td>
                    </tr>
                  ) : (
                    currentData?.map((res, key) => {
                      return (
                        <tr key={key}>
                          <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                          <td>{res.name}</td>
                          <td>{res.code}</td>
                          <td>{res.symbol}</td>
                          <td>
                            <button
                              className="btn btn-primary w-[100px] mr-1 mb-1 table-btn"
                              onClick={() => editCurrency(res._id)}
                              style={{ borderRadius: "5px", marginRight: "10px" }}
                            >
                              <span className="fa fa-edit mr-1"></span>
                              Edit
                            </button>
                            <button
                              className="btn btn-danger w-[100px]  mb-1"
                              onClick={() => deleteCurrency(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-trash mr-1"></span>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            {currentData.length === 0 ? (
              null
            ) : (
              <nav aria-label="...">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCurrency;
