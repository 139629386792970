import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";
import PhoneInput from "react-phone-input-2";

const AdminComplainDetail = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();

    const [name, setName] = useState("");
    const [collapsed, setCollapsed] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [data, setData] = useState();


    useEffect(() => {
        setData(location?.state)
    }, []);

    const HandleBack = () => {
        navigate("/admin/Complain");
    };


    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const formattedDate = new Date(data?.createdAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Admin User Edit</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Complaints Details</h2>
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 sm-col-12">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        disabled={true}
                                        type="text"
                                        value={data?.firstName}
                                        className="form-control form-control-light"
                                        placeholder="First Name"
                                        name="FullName"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 sm-col-12">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        disabled={true}
                                        type="text"
                                        value={data?.lastName}
                                        className="form-control form-control-light"
                                        placeholder="Last Name"
                                        name="FullName"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 sm-col-12">
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input
                                        disabled={true}
                                        type="text"
                                        value={data?.email}
                                        className="form-control form-control-light"
                                        placeholder="Email Address"
                                        name="Email Address"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 sm-col-12">
                                <div className="form-group">
                                    <label>Telephone Number</label>
                                    <PhoneInput
                                        disabled={true}
                                        type="text"
                                        value={data?.telNo}
                                        inputStyle={{ width: "100%" }}
                                        placeholder="Telephone Number"
                                        name="PhoneNumber"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 sm-col-12">
                                <div className="form-group">
                                    <label>Complain</label>
                                    <textarea
                                        disabled={true}
                                        type="text"
                                        value={data?.comment}
                                        className="form-control form-control-light"
                                        placeholder="Complain"
                                        name="Comment"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 sm-col-12">
                                <div className="form-group">
                                    <label>Date</label>
                                    <textarea
                                        disabled={true}
                                        type="text"
                                        value={formattedDate}
                                        className="form-control form-control-light"
                                        placeholder="Date"
                                        name="Comment"
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            type="Submit"
                            onClick={HandleBack}
                            className="btn btn-primary"
                        >
                            <span className="fa fa-reply"></span> Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminComplainDetail;
