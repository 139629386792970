import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AdminUserEdit = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const [name, setName] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [data, setData] = useState();

  useEffect(() => {
    setData(location?.state);
  }, []);

  const HandleBack = () => {
    navigate("/admin/schedule");
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin User Edit</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Appointment Details</h2>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    disabled={true}
                    type="text"
                    value={data?.fullname}
                    className="form-control form-control-light"
                    placeholder="Full Name"
                    name="FullName"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    disabled={true}
                    type="text"
                    value={data?.email}
                    className="form-control form-control-light"
                    placeholder="Email Address"
                    name="Email Address"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Phone Number</label>
                  <PhoneInput
                    country={"th"}
                    value={data?.phonenumber}
                    inputStyle={{ width: "100%" }}
                    placeholder="Telephone"
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Time to contact</label>
                  <input
                    disabled={true}
                    type="time"
                    value={data?.date}
                    className="form-control form-control-light"
                    placeholder="Date"
                    name="Date"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Comment</label>
                  <input
                    disabled={true}
                    type="text"
                    value={data?.comment}
                    className="form-control form-control-light"
                    placeholder="Comment"
                    name="Comment"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Property Name</label>
                  <input
                    disabled={true}
                    type="text"
                    value={data?.property?.BasicInformation?.name}
                    className="form-control form-control-light"
                    placeholder="Property Name"
                    name="PropertyName"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 sm-col-12">
                <div className="form-group">
                  <label>Property Type</label>
                  <a
                    target="_blank"
                    href={`https://buyhomeforless.com/listing-details-v1/${data?.property?._id}`}
                  >
                    <h6>{data?.property?.BasicInformation?.type}</h6>
                  </a>
                </div>
              </div>
            </div>
            <button
              type="Submit"
              onClick={HandleBack}
              className="btn btn-primary"
            >
              <span className="fa fa-reply"></span> Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserEdit;
