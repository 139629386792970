import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminHeaderCreate = (props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [symbol, setSymbol] = useState("");
  const [code, setCode] = useState("");
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onCancel = () => {
    navigate("/admin/headercontent");
  };
  const postData = async () => {
    if (title === "") {
      toast({
        title: "Error",
        description: "Titlt feild is empty!!!",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    const formData = {
      title: title,

    };
    setFlag(true);
    await axios
      .post(
        `${BASE_URL}/NavList/api/mainlists`,
        formData
      )
      .then((res) => {
        setFlag(false);
        toast({
          title: "Success",
          description: "It has been saved successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        navigate("/admin/headercontent");
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Helmet>
        <title>
          Buy Home For Less | Admin Currency Create
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Header Create </h2>
          </div>
          <div
            className="acr-user-content"
            style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{
                width: "70%",
                padding: "2%",
              }}
            >
              <div className="form-group">
                <label>Create Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Title"
                  name="Title"
                />
              </div>
              <div className="form-group text-right">
                {flag ? (
                  <button type="Submit" disabled className="btn btn-primary">
                    <span className="fa fa-save"></span> Saving...
                  </button>
                ) : (
                  <button
                    type="Submit"
                    onClick={() => postData()}
                    className="btn btn-primary"
                  >
                    <span className="fa fa-save"></span> Save
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => onCancel()}
                >
                  <span className="fa fa-reply"></span> Cancel
                </button>
              </div>
            </form>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeaderCreate;
