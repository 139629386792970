import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminPriceRangeCreate = (props) => {
    const navigate = useNavigate();
    const toast = useToast();

    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [id, setId] = useState();
    const [collapsed, setCollapsed] = useState(false);
    const [data, setData] = useState([]);

    const [flag, setFlag] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onCancel = () => {
        navigate("/admin/pricerange");
    };
    useEffect(() => {
        axios
            .get(`${BASE_URL}/admin/get-properties`)
            .then((res) => {
                setData(res.data.result);
                setId(res.data.result[0]._id)
            });
    }, []);
    const postData = async () => {
        if (minPrice === "" || maxPrice === "") {
            toast({
                title: "Error",
                description: "Price field is empty!",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }



        if (maxPrice <= minPrice) {
            toast({
                title: "Error",
                description: "Range is inCorrect",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }

        const formData = {
            minRange: minPrice,
            maxRange: maxPrice,
            submitListingProp: id
        };

        setFlag(true);
        await axios
            .post(
                `${BASE_URL}/Price/create`,
                formData
            )
            .then((res) => {
                setFlag(false);
                toast({
                    title: "Success",
                    description: "It has been saved successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
                navigate("/admin/pricerange");
            })
            .catch((err) => {
                setFlag(false);
                setError(true);
                setErrorMsg(err);
            });
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <Helmet>
                <title>
                    Buy Home For Less | Admin Price Create
                </title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Price Range Create</h2>
                    </div>
                    <div
                        className="acr-user-content"
                        style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
                    >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            style={{
                                width: "70%",
                                padding: "2%",
                            }}
                        >
                            <div className="form-group">
                                <label>Min-Price</label>
                                <input
                                    type="number"
                                    value={minPrice}
                                    onChange={(e) => setMinPrice(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="price"
                                    name="name"
                                />
                            </div>
                            <div className="form-group">
                                <label>Max-Price</label>
                                <input
                                    type="number"
                                    value={maxPrice}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="price"
                                    name="name"
                                />
                            </div>
                            <div className="form-group">
                                <label>Properties</label>
                                <div className="select-with-icon relative">
                                    <select className="form-control form-control-light" onChange={(e) => setId(e.target.value)}>
                                        {data && data.map(item => (
                                            <option key={item._id} value={item._id}>{item.BasicInformation.name}</option>
                                        ))}
                                    </select>
                                    <div className="dropdown-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down dropdown-icon-svg" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M7.646 10.854a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L8 9.293l4.354-4.353a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1 0 .708z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group text-right">
                                {flag ? (
                                    <button type="Submit" disabled className="btn btn-primary">
                                        <span className="fa fa-save"></span> Saving...
                                    </button>
                                ) : (
                                    <button
                                        type="Submit"
                                        onClick={() => postData()}
                                        className="btn btn-primary"
                                    >
                                        <span className="fa fa-save"></span> Save
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => onCancel()}
                                >
                                    <span className="fa fa-reply"></span> Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPriceRangeCreate;
