import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";

const { Sider } = Layout;

const AdminSider = ({ collapsed, toggleCollapsed }) => {
  const navig = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(["dashboard"]);
  const logout = () => {
    localStorage.removeItem("userInfo");
    window.location.replace("/");
  };

  const nav = [
    { name: "Home", key: "/" },
    { name: "User", key: "/admin/users" },
    { name: "Category", key: "/admin/categories" },
    {
      name: "Property",
      key: "/admin/properties",
      children: [
        { name: "Rent Property", key: "/admin/rentproperty" },
        { name: "Sell Property", key: "/admin/sellproperty" },
      ],
    },
    { name: "Currency", key: "/admin/currencies" },
    { name: "Sell Us", key: "/admin/Sellus" },
    { name: "Location", key: "/admin/locations" },
    { name: "Customer Contact", key: "/admin/Contactpage" },
    { name: "Become Agent", key: "/admin/Becomeouragent" },
    { name: "Near Type", key: "/admin/nearbytypes" },
    { name: "FAQ Manage", key: "/admin/faqs" },
    { name: "Footer Content", key: "/admin/footercontent/create" },
    { name: "Blog & News", key: "/admin/blognews" },
    { name: "Agent Profile", key: "/admin/agentsays" },
    { name: "Testimony", key: "/admin/testimonial" },
    {
      name: "Best Deal", key: "/admin/bestdeal",
      children: [
        { name: "Best Deal Rent", key: "/admin/bestdealRent" },
        { name: "Best Deal Sell", key: "/admin/bestdealSell" },
      ]
    },
    {
      name: "Top Listings", key: "/admin/topList",
      children: [
        { name: "Toplisting Rent", key: "/admin/topListrent" },
        { name: "Toplisting Sell", key: "/admin/topListsell" },
      ]
    },
    { name: "Our Moto", key: "/admin/ourmoto" },
    { name: "Header", key: "/admin/headercontent" },
    { name: "Banner", key: "/admin/banner" },
    { name: "Home Seo", key: "/admin/seo" },
    { name: "Schedule and Appointments", key: "/admin/schedule" },
    { name: "Complaints", key: "/admin/Complain" },
    { name: "Promotions", key: "/admin/promotion" },
    { name: "Logout", key: "logout" },
  ];

  const navItem = (key) => {
    setSelectedKeys([key]);
    navig(key);
  };

  useEffect(() => {
    const currentKey = location.pathname;
    setSelectedKeys([currentKey]);
  }, [location.pathname]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Layout style={{ flex: 1 }}>
        <Sider
          width={collapsed ? 0 : 280}
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          collapsedWidth={0}
          style={{
            backgroundColor: '#373A47',
            minHeight: '100vh',
            overflowY: 'auto',
          }}
          theme="dark"
        >
          {!collapsed && (
            <Menu
              mode="inline"
              selectedKeys={selectedKeys}
              theme="dark"
              style={{ backgroundColor: '#373A47', color: '#fff', fontSize: '22px' }}
            >
              {nav.map((item) => (
                item.children ? (
                  <SubMenu
                    key={item.key}
                    title={item.name}
                    style={{
                      backgroundColor: selectedKeys.includes(item.key) ? '#373A47' : '#373A47',
                      color: selectedKeys.includes(item.key) ? '#1563CF' : '#fff',
                      fontSize: '22px',
                    }}
                  >
                    {item.children.map((child) => (
                      <Menu.Item
                        key={child.key}
                        onClick={() => navItem(child.key)}
                        style={{
                          backgroundColor: selectedKeys.includes(child.key) ? '#373A47' : '#373A47',
                          color: selectedKeys.includes(child.key) ? '#1563CF' : '#fff',
                          fontSize: '22px',
                        }}
                      >
                        {child.name}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    className={item.key == 'logout' && 'logoutBtnSlider'}
                    key={item.key}
                    onClick={() => item.key == 'logout' ? logout() : navItem(item.key)}
                    style={{
                      backgroundColor: selectedKeys.includes(item.key) ? '#373A47' : '#373A47',
                      color: selectedKeys.includes(item.key) ? '#1563CF' : '#fff',
                      fontSize: '22px',
                    }}
                  >
                    {item.name}
                  </Menu.Item>
                )
              ))}
            </Menu>
          )}
        </Sider>
      </Layout>
    </div>
  );
};

export default AdminSider;
