import React, { useState, Fragment, useEffect} from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import _ from "lodash";

const Locationtab = ({ data, locationData }) => {
  const [lat, setLat] = useState(""); // Empty by default
  const [long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [embedCode, setEmbedCode] = useState("");
  const [city, setCity] = useState("");
  const [tumbol, setTumbol] = useState("");
  const [provice, setprovice] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState("");
  const [locationDetails, setLocationDetails] = useState("");
  const defaultPosition = [13.736717, 100.523186]; // Default coordinates
  const position =
    lat && long && !isNaN(lat) && !isNaN(long)
      ? [parseFloat(lat), parseFloat(long)]
      : defaultPosition;
  const customMarker = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/assets/img/misc/marker.png",
    iconSize: [50, 50],
    iconAnchor: [25, 5],
  });

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleMarkerMove = (e) => {
    const { lat, lng } = e.target._latlng;
    setLat(parseFloat(lat.toFixed(6))); // Ensure lat is a number
    setLong(parseFloat(lng.toFixed(6))); // Ensure long is a number
  };

  useEffect(() => {
    if (data?.address) {
      setAddress(data.address);
      fetchCoordinates(data.address);
    }
    if (data?.tumbol) setTumbol(data.tumbol);
    if (data?.city) setCity(data.city);
    if (data?.provice) setprovice(data?.provice);
    if (data?.zipcode) setZipcode(data.zipcode);
    if (data?.country) setCountry(data.country);
    if (data?.embedCode) setEmbedCode(data.embedCode);
  }, [data]);

  useEffect(() => {
    if (address == "") {
      setCountry("");
      setprovice("");
      setZipcode("");
      setCity("");
    }
    if (address || (lat && long && lat !== 13.736717 && long !== 100.523186)) {
      fetchCoordinates(address);
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      setLoading(true)
      fetchCoordinates(address); // First API call
  
      // Wait for 2 seconds before triggering reverse geocoding
      setTimeout(() => {
        if (lat && long) {
          fetchReverseGeocodingData(lat, long); // Second API call with a 2-second delay
        }
      }, 2000); // 2 seconds delay (2000 milliseconds)
    }
  }, [lat, long, address]);;

  useEffect(() => {
    locationData({
      embedCode,
      address,
      city,
      provice: provice || data?.provice,
      zipcode,
      country,
      tumbol,
      lat,
      long,
    });
  }, [embedCode, address, city, provice, zipcode, country, tumbol, lat, long]);

  const fetchCoordinates = async (address) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`
      );

      if (response.data && response.data.length > 0) {
        const { lat, lon, display_name } = response.data[0];
        setLat(lat);
        setLong(lon);
        setLocationDetails(display_name);
      } else {
        setLocationDetails("");
      }
    } catch (error) {
      console.error("Error fetching location coordinates: ", error);
    }
    finally{
      setLoading(false);
    }
  };

  const fetchReverseGeocodingData = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      if (response.data) {
        const { address } = response.data;
        setCountry(address.country || "");
        setCity(
          address.city ||
            address.town ||
            address.village ||
            address.hamlet ||
            address.suburb ||
            address.subdistrict ||
            address.district ||
            ""
        );
        setprovice(address.state || address.region || "");
        setZipcode(address.postcode || "");
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data: ", error);
    }
  };

  const handleLatChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setLat(""); // Allows the input to be empty
    } else {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setLat(parsedValue);
      }
    }
  };

  const handleLongChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setLong(""); // Allows the input to be empty
    } else {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setLong(parsedValue);
      }
    }
  };

  const handleEmbedCodeChange = (e) => {
    setEmbedCode(e.target.value);
  };
  return (
    <Fragment>
      <div className="form-group submit-listing-map">
        {embedCode ? (
          <iframe
            width="100%"
            height="95%"
            title="direction"
            src={embedCode}
          ></iframe>
        ) : (
          <MapContainer zoom={13} center={position}>
            <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <Marker
              position={position}
              icon={customMarker}
              draggable={true}
              eventHandlers={{ moveend: handleMarkerMove }}
            >
              <Popup position={position}>
                Current location:{" "}
                <pre>{JSON.stringify(locationDetails, null, 2)}</pre>
              </Popup>
            </Marker>
          </MapContainer>
        )}
      </div>
      <div className="form-group">
        <span className="acr-form-notice">
          Drag and drop the marker to identify your <b>Latitude</b> and{" "}
          <b>Longitude</b>{" "}
        </span>
      </div>
      <div className="row">
        <div className="col-md-12 form-group">
          <label>Full Address (Optional)</label>
          <input
            type="text"
            value={address}
            onChange={handleAddressChange}
            name="address"
            className="form-control"
            placeholder="Full Address (Optional)"
          />
            {loading?(<p>Loading....</p>):""}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-3 form-group">
          <label>Country</label>
          <input
            type="text"
            name="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="form-control"
            placeholder="Country"
          />
        </div>
        <div className="col-lg-3 col-md-3 form-group">
          <label>Tumbol/kwang</label>
          <input
            type="text"
            name="Tumbol"
            value={tumbol}
            onChange={(e) => setTumbol(e.target.value)}
            className="form-control"
            placeholder="Tumbol"
          />
        </div>
        <div className="col-lg-3 col-md-3 form-group">
          <label>Amphor/city</label>
          <input
            type="text"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="form-control"
            placeholder="City"
          />
        </div>
        <div className="col-lg-3 col-md-3 form-group">
          <label>provice/State</label>
          <input
            type="text"
            name="provice"
            value={provice}
            onChange={(e) => setprovice(e.target.value)}
            className="form-control"
            placeholder="provice/State"
          />
        </div>
        <div className="col-lg-3 col-md-3 form-group">
          <label>Zipcode</label>
          <input
            type="text"
            name="zipcode"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
            className="form-control"
            placeholder="Zipcode"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <label>Longitude (Optional)</label>
          <input
            type="text"
            name="lng"
            value={long}
            onChange={handleLongChange}
            id="lngVal"
            className="form-control"
            placeholder="Longitude"
          />
        </div>
        <div className="col-md-6 form-group">
          <label>Latitude (Optional)</label>
          <input
            type="text"
            name="lat"
            value={lat}
            onChange={handleLatChange}
            id="latVal"
            className="form-control"
            placeholder="Latitude"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 form-group">
          <label>Embed Link</label>
          <input
            type="text"
            value={embedCode}
            onChange={handleEmbedCodeChange}
            name="embedCode"
            className="form-control"
            placeholder="Embed Link"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Locationtab;
