import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";
import { State } from "country-state-city";
const AdminTestimonialCreate = (props) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [companyname, setcompanyname] = useState("");
    const [language, setLanguage] = useState("");
    const [Experience, setExperience] = useState();
    const [name, setName] = useState("");
    const [Area, setArea] = useState("");
    const [description, setDescription] = useState("");
    const [rating, setRating] = useState("1");
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [icon, setIcon] = useState("");
    const [img, setImg] = useState("");
    const [iconUrl, setIconUrl] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [username, setUsername] = useState("");
    const [destination, setDestination] = useState("");
    const [comment, setcomment] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [provinceName, setProvinceName] = useState();
    const [collapsed, setCollapsed] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        // Get all states/provinces
        const allStates = State.getAllStates();
        setProvinces(allStates);
    }, []);
    const onCancel = () => {
        navigate("/admin/testimonial");
    };
    const postData = async () => {
        if (name === "") {
            toast({
                title: "Error",
                description: "Enter Title",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        if (!img) {
            toast({
                title: "Error",
                description: "Select Image",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        if (!username) {
            toast({
                title: "Error",
                description: "username",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        if (!description) {
            toast({
                title: "Error",
                description: "Description",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        if (!rating) {
            toast({
                title: "Error",
                description: "Rating",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        const formData = new FormData();
        formData.append("title", name);
        formData.append("description", description);
        formData.append("userName", username);
        formData.append("destination", destination);
        formData.append("rating", rating);
        formData.append("comment", comment);
        formData.append("companyname", companyname);
        formData.append("img", img);
        formData.append("language", language);
        formData.append("Area", Area);
        formData.append("Experience", Experience);
        formData.append("provinceName", provinceName);
        setFlag(true);
        await axios
            .post(`${BASE_URL}/admin/pepleSay/save`, formData)
            .then((res) => {
                setFlag(false);
                toast({
                    title: "Success",
                    description: "It has been saved successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
                navigate("/admin/testimonial");
            })
            .catch((err) => {
                setFlag(false);
                setError(true);
                setErrorMsg(err);
            });
    };
    const onUploadIcon = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setIconUrl(base64);
        setIcon(e.target.files[0]);
    };
    const onUploadImage = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setImgUrl(base64);
        setImg(e.target.files[0]);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Admin People Says Create</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Create Testimonial</h2>
                    </div>
                    <div
                        className="acr-user-content"
                        style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
                    >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            style={{
                                width: "70%",
                                padding: "2%",
                            }}
                        >
                            <div className="form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Username"
                                    name="username"
                                />
                            </div>
                            <div className="form-group">
                                <label>Destination</label>
                                <input
                                    type="text"
                                    value={destination}
                                    onChange={(e) => setDestination(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Destination"
                                    name="destination"
                                />
                            </div>

                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Title"
                                    name="username"
                                />
                                <div className="form-group mt-3">
                                    <label>Company Name</label>
                                    <input
                                        type="text"
                                        value={companyname}
                                        onChange={(e) => setcompanyname(e.target.value)}
                                        className="form-control form-control-light"
                                        placeholder="Title"
                                        name="username"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Description"
                                    name="description"
                                />
                            </div>
                            <div className="form-group">
                                <label>Comment</label>
                                <input
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setcomment(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="comment"
                                    name="comment"
                                />
                            </div>
                            <div className="form-group">
                                <label>Experience</label>
                                <input
                                    type="text"
                                    value={Experience}
                                    onChange={(e) => setExperience(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Experience"
                                    name="Experience"
                                />
                            </div>
                            <div className="form-group">
                                <label>Select Language</label>
                                <select
                                    className="form-control"
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                >
                                    <option>Select language</option>
                                    <option value={"English"}>English</option>
                                    <option value={"Thai"}>Thai</option>
                                    <option value={"Indian"}>Indian</option>
                                    <option value={"Chineese"}>Chineese</option>
                                    <option value={"Russian"}>Russian</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Area Cover</label>
                                <input
                                    type="text"
                                    value={Area}
                                    onChange={(e) => setArea(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Area Cover"
                                    name="Area"
                                />
                            </div>
                            <div className="form-group">
                                <label>Provinces</label>
                                <select
                                    className="form-control"
                                    value={provinceName}
                                    onChange={(e) => setProvinceName(e.target.value)}>
                                    {provinces.map((province) => (
                                        <option key={province.id} value={province.name}>
                                            {province.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Rating Stars</label>
                                <select
                                    className="form-control form-control-light"
                                    onChange={(e) => setRating(e.target.value)}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="img" className="cursor-pointer">
                                    <i className="fas fa-upload"></i> Select Image
                                </label>
                                <input
                                    onChange={onUploadImage}
                                    type="file"
                                    id="img"
                                    name="img"
                                    style={{ display: "none" }}
                                />
                                {imgUrl ? (
                                    <img src={`${imgUrl}`} alt="NoImage" style={{ width: "200px" }} />
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="form-group text-right">
                                {flag ? (
                                    <button type="Submit" disabled className="btn btn-primary">
                                        <span className="fa fa-save"></span> Saving...
                                    </button>
                                ) : (
                                    <button
                                        type="Submit"
                                        onClick={() => postData()}
                                        className="btn btn-primary"
                                    >
                                        <span className="fa fa-save"></span> Save
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={() => onCancel()}
                                >
                                    <span className="fa fa-reply"></span> Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminTestimonialCreate;
