import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminItemcontent = (props) => {
    const navigate = useNavigate();
    const toast = useToast();

    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        axios
            .get(`${BASE_URL}/NavList/mainlists/${localStorage.getItem("id")}/sublists/${localStorage.getItem("subid")}/items`)
            .then((res) => {
                setFlag(true);
                if (res.data) {
                    setData(res.data);
                }
            });
    }, []);

    const editFeature = (item) => {
        navigate(`/admin/item/${item._id}`, { state: item });
    };
    const deleteFeature = (id) => {
        axios
            .delete(`${BASE_URL}/NavList/mainlists/${localStorage.getItem("id")}/sublists/${localStorage.getItem("subid")}/items/${id}`)
            .then((res) => {
                var filteredData = data.filter(item => !id.includes(item._id));
                setData(filteredData);
                toast({
                    title: "Success",
                    description: "item deleted successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
            })
            .catch((err) => {
                setError(true);
                setErrorMsg(err);
            });
    };

    const addFeature = () => {
        navigate("/admin/item/create");
    };


    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Our Moto</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Item Content</h2>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "94%",
                            margin: "1%",
                        }}
                    >
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => addFeature()}
                        >
                            <span className="fa fa-plus"></span> Add Item
                        </button>
                    </div>
                    <div className="table-container-main">
                        <div className="table-container-child">
                            <table
                                className="table-striped table-bordered text"
                                style={{ width: "90%", margin: "1%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length === 0 && flag ? (
                                        <tr className="text-center">
                                            <td colSpan="4">No Data</td>
                                        </tr>
                                    ) : data?.length === 0 && flag === false ? (
                                        <tr className="text-center">
                                            <td colSpan="4">Loading ...</td>
                                        </tr>
                                    ) : (
                                        data?.map((res, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{res.title}</td>
                                                    <td>
                                                        <img
                                                            src={`${res.image}`}
                                                            alt="NoIcon"
                                                            style={{ width: "60px" }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => editFeature(res)}
                                                            style={{ borderRadius: "5px", marginRight: "10px" }}
                                                        >
                                                            <span className="fa fa-edit"></span>
                                                            Edit
                                                        </button>
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={() => deleteFeature(res._id)}
                                                            style={{ borderRadius: "5px" }}
                                                        >
                                                            <span className="fa fa-trash"></span>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminItemcontent;
