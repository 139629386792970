import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, useToast } from "@chakra-ui/react";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";
import { Button} from "antd"; // Using Ant Design components
import Social from "./Social";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
const AdminPromotion = (props) => {
  const toast = useToast();
  const [name, setName] = useState();
  const [show, setShow] = useState();
  const [fileType, setFileType] = useState("");
  const [FilterData, setFilterData] = useState("");
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [isLink, setisLink] = useState(false);
  const [getLink, setGetLink] = useState();
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    axios
      .get(`${BASE_URL}/admin/popup`)
      .then((res) => {
        setName(res?.data?.data?.file);
        setFileType(res?.data?.data?.fileType);
      })
      .catch((err) => console.log("error", err));
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setShow(base64);
      setFileType(file.type);
    }
  };
  const postData = async () => {
    if (name === "") {
      toast({
        title: "Enter image or video",
        description: "image or video",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("file", name);
    formData.append("check", true);
    formData.append("fileType", fileType);
    await axios
      .post(`${BASE_URL}/admin/popup`, formData)
      .then((res) => {
        setFlag(false);
        toast({
          title: "Success",
          description: "It has been saved successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        return true;
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const renderMedia = () => {
    if (fileType?.startsWith("image/")) {
      return (
        <img
          style={{ margin: "auto" }}
          width="45%"
          height="50vh"
          src={show ? show : name}
          alt="Promotion Media"
        />
      );
    } else if (fileType?.startsWith("video/")) {
      return (
        <video
          style={{ margin: "auto", width: "50vh" }}
          width="50vh"
          controls
          src={show ? show : name}
        />
      );
    }
    return null;
  };
  const getLinks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/socialLinks`);
      setGetLink(response?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getLinks();
  }, [isLink]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/socialLinks/${id}`);
      if (response?.data?.message == "Social link deleted successfully") {
        toast({
          title: "Delete Successfully",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        const filterdata=getLink?.filter((item)=>item._id !== id)
        setGetLink(filterdata)
      }
    } catch (error) {}
  };
  const handleEdit = (id) => {
    if (id) {
      const filterdata = getLink?.filter((item) => item._id == id);
      setFilterData(filterdata);
      setisLink(true);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Seo</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Promotion Manage</h2>
          </div>
          <div style={{ display: "contents" }}>{renderMedia()}</div>
          <div
            className="acr-user-content"
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: sidebarOpen ? "20vh" : "0",
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{
                width: "70%",
                padding: "2%",
              }}
            >
              <div className="form-group">
                <label>Add Promotion Image or Video</label>
                <input
                  type="file"
                  defaultValue={name}
                  onChange={(e) => {
                    handleFileChange(e);
                    setName(e.target.files[0]);
                  }}
                  className="form-control form-control-light"
                  placeholder="Title"
                  name="username"
                />
              </div>
              <div className="form-group text-right">
                {flag ? (
                  <button type="Submit" disabled className="btn btn-primary">
                    <span className="fa fa-save"></span> Saving...
                  </button>
                ) : (
                  <>
                    <button
                      type="Submit"
                      onClick={() => postData()}
                      className="btn btn-primary"
                    >
                      <span className="fa fa-save"></span> Save
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>


          <div className="flex flex-start mb-2">
            <button onClick={() => setisLink(true)} className="btn btn-danger">
              Add Social Link
            </button>
          </div>
          <Box p={4} borderWidth={1} borderRadius="md" boxShadow="md">
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>S.no</Th>
                    <Th>Name</Th>
                    <Th>Image</Th>
                    <Th>Link</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                    {getLink &&
                      getLink?.map((item, index) => (
                        <>
                        <Tr>
                          <Td>{index + 1}</Td>
                          <Td>{item?.name}</Td>
                          <Td>
                            <img src={item?.image} style={{height:"40px",width:"40px",objectFit:"cover"}}/>
                          </Td>
                          <Td>
                            <Box
                              maxWidth="200px" // Set a max width for the link container
                              overflow="hidden" // Hide overflow
                              textOverflow="ellipsis" // Add ellipsis for overflowed text
                              whiteSpace="nowrap" // Prevent line breaks
                            >
                              <a
                                href="https://example.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item?.link}
                              </a>
                            </Box>
                          </Td>
                          <Td>
                            <Button
                              colorScheme="blue"
                              style={{backgroundColor:"blue" , color:"white"}}
                              size="sm"
                              mr={2}
                              className="mr-2"
                              onClick={() => handleEdit(item?._id)}
                            >
                              Edit
                            </Button>
                            <Button
                              colorScheme="red"
                              style={{backgroundColor:"red", color:"white"}}
                              size="sm"
                              onClick={() => handleDelete(item?._id)}
                            >
                              Delete
                            </Button>
                          </Td>
                  </Tr>
                        </>
                      ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
      {isLink && (
        <Social isOpen={isLink} setisLink={setisLink} FilterData={FilterData} setFilterData={setFilterData} />
      )}
    </div>
  );
};

export default AdminPromotion;
