import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminProperty = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");



  const fetchProperties = (page) => {
    axios
      .get(`${BASE_URL}/admin/get-properties/${props.type}?page=${page}&limit=10`)
      .then((res) => {
        setFlag(true);
        setData(res.data.result);
        setTotalPages(res.data.pagination.totalPages);
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err.message);
      });
  };
  useEffect(() => {
    fetchProperties(currentPage);
  }, [props.type, currentPage]);
  const detailProperty = (id) => {
    navigate(`/admin/property/${id}/detail`);
  };

  const editProperty = (id) => {
    navigate(`/admin/property/${id}`);
  };

  const deleteProperty = (id) => {
    axios
      .delete(`${BASE_URL}/admin/delete/property/${id}`)
      .then((res) => {
        fetchProperties(currentPage);
        toast({
          title: "Success",
          description: "Property deleted successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err.message);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  const addProperty = () => {
    navigate("/admin/property/create");
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Property</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div className="d-flex">
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div className="flex-grow-1 p-3">
          <div className="text-center mb-4">
            <h2>Property Manage</h2>
          </div>
          <div className="d-flex justify-content-end mb-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={addProperty}
            >
              <span className="fa fa-plus"></span> Add Property
            </button>
          </div>
          <div
            className="container table-responsive"
            style={{ overflowX: "scroll" }}
          >
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>No</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>{props.url === "/admin/rentproperty" ? "Rental Price" : "Price"}</th>
                  <th>Space(sqm)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && flag ? (
                  <tr className="text-center">
                    <td colSpan="9">No Data</td>
                  </tr>
                ) : data.length === 0 && flag === false ? (
                  <tr className="text-center">
                    <td colSpan="9">Loading ...</td>
                  </tr>
                ) : (
                  data.map((res, key) => {
                    return (
                      <tr key={key}>
                        <td>{(currentPage - 1) * 10 + (key + 1)}</td>
                        <td>{res.Details.id}</td>
                        <td>{res.BasicInformation.name}</td>
                        <td>
                          {res.BasicInformation.description.slice(0, 10) + " .."}
                        </td>
                        <td>{res.BasicInformation.type}</td>
                        <td>{res.BasicInformation.status}</td>
                        <td>
                          {res.BasicInformation.listingType === "Rent"
                            ? `${res.BasicInformation.currency}${res.BasicInformation.rentalPrice}/${res.BasicInformation.period}`
                            : `${res.BasicInformation.currency}${res.BasicInformation.price}`}
                        </td>
                        <td>{res.BasicInformation.space}</td>
                        <td className="text-start">
                          <div className="property-page-buttons">
                            <button
                              className="btn btn-info mr-1 mb-1 w-45"
                              onClick={() => detailProperty(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fas fa-info-circle"></span> Detail
                            </button>
                            <button
                              className="btn btn-primary mr-1 mb-1 w-45"
                              onClick={() => editProperty(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-edit"></span> Edit
                            </button>
                            <button
                              className="btn btn-danger w-100"
                              onClick={() => deleteProperty(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-trash"></span> Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            {error ? (
              <div
                className="alert alert-danger d-flex justify-content-between align-items-center w-75"
                role="alert"
              >
                <span>{errorMsg}</span>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setError(false)}
                ></button>
              </div>
            ) : null}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center gap-2">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {renderPaginationButtons()}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProperty;
